<template>
  <v-list v-if="isList" class="post-latest">
    <router-link
      v-for="post in posts"
      :key="post.id"
      :to="routerLink(post.slug)"
    >
      <v-list-item class="px-0 py-3" v-html="post.title.rendered">
      </v-list-item>
      <v-divider></v-divider>
    </router-link>
  </v-list>
  <div v-else class="post-latest">
    <v-row>
      <v-col cols="2" md="4" v-for="post in posts" :key="post.id">
        <component
          :is="isRecipe ? 'RecipeCard' : 'ArticleCard'"
          :recipe="post"
          :posts="post"
        ></component>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex justify-end align-end">
        <router-link
          class="d-flex align-center justify-center previous-page black--text"
          :to="{
            name: 'ArticleList'
          }"
        >
          <span class="h4 pr-2 text-decoration-underline font-weight-bold">{{
            $t("article.showAll")
          }}</span>
          <v-icon>$arrowForward</v-icon></router-link
        >
      </v-col>
    </v-row>
  </div>
</template>
<style scoped lang="scss">
.post-latest {
  a {
    text-decoration: none;
  }
}
</style>
<script>
import RecipeCard from "@/components/wordpress/RecipeCard.vue";
import ArticleCard from "@/components/wordpress/ArticleCard.vue";
import CMService from "~/service/cmService";

export default {
  name: "LatestPost",
  components: { RecipeCard, ArticleCard },
  props: {
    isRecipe: {
      type: Boolean,
      default: false
    },
    isList: {
      type: Boolean,
      default: true
    },
    num: {
      type: Number,
      default: 4
    }
  },
  data() {
    return {
      filters: [
        // Excluding WordPress default "Uncategorized" / Senza categoia
        {
          filterName: "exclude",
          filterValue: 1
        }
      ],
      posts: null
    };
  },
  mounted() {
    this.fetchPosts();
  },
  methods: {
    async fetchPosts() {
      let params = [
        {
          filterName: "orderby",
          filterValue: "date"
        },
        {
          filterName: "order",
          filterValue: "desc"
        }
      ];
      const res = await CMService.getCustomPostByFilters(
        this.isRecipe ? "recipe" : "posts",
        params,
        this.num
      );

      if (res && res.data && res.data.length) {
        this.posts = res.data;
      }
    },
    routerLink(slug) {
      let route = null;
      if (this.isRecipe) {
        route = {
          name: "RecipeDetail",
          params: { postName: slug }
        };
      } else {
        route = {
          name: "ArticleDetail",
          params: { postName: slug }
        };
      }
      return route;
    }
  }
};
</script>
