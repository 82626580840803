<template>
  <div>
    <h3 class="my-6">{{ $t("posts.footer.browse") }}</h3>
    <PostCategories :extended="true"></PostCategories>
  </div>
</template>
<style scoped lang="scss"></style>
<script>
import PostCategories from "@/components/wordpress/PostCategories.vue";

export default {
  name: "PostFooter",
  components: {
    PostCategories
  }
};
</script>
