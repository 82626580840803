<template>
  <div v-if="extended" class="masonry-layout">
    <div
      class="masonry-panel"
      v-for="parentCategory in categories"
      :key="parentCategory.id"
    >
      <div class="masonry-panel-content">
        <h4>{{ parentCategory.name }}</h4>
        <v-list class="post-categories">
          <v-list-item
            v-for="(category, index) in parentCategory.children"
            :key="index"
            :to="{
              name: 'ArticleList',
              query: { cat: category.id.toString() }
            }"
          >
            <v-icon class="pr-2">$tag</v-icon
            ><span v-html="category.name"></span>
          </v-list-item>
        </v-list>
      </div>
    </div>
    <div class="masonry-panel">
      <div class="masonry-panel-content">
        <h4>{{ $t("posts.sidebar.recipes") }}</h4>
        <v-list class="post-courses">
          <v-list-item
            v-for="course in courses"
            :key="course.id"
            :to="{ name: 'RecipeList', query: { course: course.name } }"
          >
            <v-icon class="pr-2">$tag</v-icon>{{ course.name }}
          </v-list-item>
        </v-list>
      </div>
    </div>
  </div>
  <div v-else>
    <h6 class="d-flex align-center">
      <v-icon class="mr-3">$folder</v-icon> {{ $t("posts.sidebar.categories") }}
    </h6>
    <v-list class="post-categories">
      <router-link
        v-for="(category, index) in categories"
        :key="index"
        :to="{ name: 'ArticleList', query: { cat: category.id.toString() } }"
      >
        <v-list-item class="px-0">
          {{ category.name }}
        </v-list-item>
        <v-divider />
      </router-link>
      <router-link :to="{ name: 'RecipeList' }">
        <v-list-item class="px-0">
          Ricette
        </v-list-item>
      </router-link>
    </v-list>
  </div>
</template>
<style scoped lang="scss">
.masonry-layout {
  a {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
}
.post-categories a,
.post-courses a {
  text-decoration: none;
}
.masonry-layout {
  column-count: 3;
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-gap: 0;
  .masonry-panel {
    break-inside: avoid;
    -webkit-column-break-inside: avoid;
    padding: 5px;
    .masonry-panel-content {
      padding: 10px;
      .v-list-item--active::before {
        background-color: initial;
      }
    }
  }
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .masonry-layout {
    column-count: 1;
    -webkit-column-count: 1;
    -moz-column-count: 1;
  }
}
</style>
<script>
import CMSService from "~/service/cmService";

export default {
  name: "PostCategories",
  data() {
    return {
      filters: [
        // Excluding WordPress default "Uncategorized" / Senza categoia
        {
          filterName: "exclude",
          filterValue: 1
        }
      ],
      categories: null,
      courses: null
    };
  },
  props: {
    extended: { type: Boolean, required: false, default: false }
  },
  mounted() {
    this.fetchCategories();
    this.fetchCourses();
  },
  methods: {
    async fetchCategories() {
      const res = await CMSService.getCustomPostByFilters(
        "categories",
        this.filters,
        50
      );
      if (res && res.data && res.data.length) {
        // Getting only parent categories
        const parentCategories = res.data.filter(
          category => category.parent === 0 && category.count > 0
        );

        // Appending children categories to parent categories
        const result = parentCategories.map(parent => {
          const children = res.data.filter(
            category => category.parent === parent.id
          );
          return { ...parent, children };
        });
        this.categories = result;
      }
    },
    async fetchCourses() {
      const result = await CMSService.getCustomPostBySlug("course");
      if (result && result.length) {
        this.courses = result;
      }
    }
  }
};
</script>
