<template>
  <v-card class="fill-height mx-auto my-2 rounded-lg" elevation="5">
    <v-img
      height="250"
      :src="posts.featured_image_url"
      :alt="`Blog: ${posts.title.rendered}`"
    ></v-img>
    <router-link
      class="card-title"
      :to="{
        name: 'ArticleList',
        query: { cat: posts.categories[0].toString() }
      }"
    >
      <v-card-subtitle class="primary--text font-weight-bold">
        {{ posts.category_names[0] }}
      </v-card-subtitle>
    </router-link>
    <v-card-title
      :style="`height:${maxHeight}px;`"
      class="card-description pa-3 body-1 font-weight-bold d-flex align-start"
      v-html="posts.title.rendered"
    ></v-card-title>

    <v-divider class="my-3" />
    <v-card-actions
      style="min-height: 60px;"
      class="pa-4 justify-space-between"
    >
      <router-link
        style="color: inherit;"
        :to="{
          name: 'ArticleDetail',
          params: { postName: posts.slug }
        }"
      >
        <span class="text-underline">Leggi l'articolo</span>
      </router-link>
      <ShareButtons
        :post="posts"
        direction="top"
        :slug="posts.slug"
      ></ShareButtons>
    </v-card-actions>
  </v-card>
</template>
<style lang="scss" scoped>
.card-title {
  text-decoration: none !important;
}
.v-speed-dial {
  &__list {
    a {
      text-decoration: none;
    }
  }
}
.social-share,
.share-btn {
  cursor: pointer;
  cursor: -moz-pointer;
  cursor: -webkit-pointer;
}
</style>
<script>
import ShareButtons from "@/components/wordpress/ShareButtons.vue";

export default {
  name: "ArticleCard",
  components: { ShareButtons },
  props: {
    posts: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      open: false,
      maxHeight: null
    };
  },
  methods: {
    sharingUrl(slug) {
      return window.location.href + slug;
    },
    setMaxHeight() {
      const elements = document.querySelectorAll(".card-description");
      let maxHeight = 0;
      for (const element of elements) {
        const elementHeight = element.clientHeight;
        if (elementHeight > maxHeight) {
          maxHeight = elementHeight;
        }
      }
      this.maxHeight = maxHeight;
      console.log(maxHeight);
    }
  },
  mounted() {
    setTimeout(() => {
      this.setMaxHeight();
    }, 300);
  }
};
</script>
