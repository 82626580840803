<template>
  <v-form
    ref="form"
    v-model="valid"
    @submit.prevent="searchRecipe"
    class="post-search white"
  >
    <div class="d-flex">
      <v-text-field
        color="secondary"
        v-model="s"
        clearable
        outlined
        hide-details="true"
        :label="
          isRecipe
            ? $t('posts.sidebar.search.recipes')
            : $t('posts.sidebar.search.articles')
        "
        prepend-inner-icon="$search"
      ></v-text-field>
    </div>
  </v-form>
</template>
<style scoped lang="scss">
.post-search {
  // box-shadow: 0px 3px 6px #00000029;
  border-radius: $border-radius-root;
  .v-btn {
    width: 100%;
  }
}
</style>
<script>
export default {
  name: "PostSearch",
  data() {
    return {
      s: null,
      course: null,
      coursesData: null,
      ingredients: null,
      ingredientsData: null,
      cuisine: null,
      cuisineData: null,
      valid: true
    };
  },
  props: {
    changePage: { type: String, default: "true" },
    isRecipe: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    searchRecipe() {
      let query = {};
      if (this.s != null && this.s.length > 0) {
        query["s"] = this.s;
      }
      if (this.changePage == "true") {
        this.$router.push({
          name: this.isRecipe ? "RecipeList" : "ArticleList",
          query
        });
      } else {
        this.$router.replace({ query });
      }
    }
  }
};
</script>
