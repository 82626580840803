<template>
  <v-card
    class="fill-height mx-auto rounded-lg d-flex flex-column"
    elevation="5"
    :to="{
      name: 'RecipeDetail',
      params: { postName: recipe.slug }
    }"
  >
    <v-img
      height="250"
      :src="recipe.featured_image_url"
      class="recipe-img"
      :alt="`Ricetta: ${recipe.title.rendered}`"
    ></v-img>
    <v-card-subtitle class="primary--text font-weight-bold text-body-2 pb-0">{{
      recipe.course
    }}</v-card-subtitle>

    <v-card-title
      class="card-description recipe-title pt-1 pb-0 mb-2"
      v-html="recipe.title.rendered"
    ></v-card-title>

    <v-card-text class="card-description-secondary">
      {{ recipe.raw_excerpt }}
    </v-card-text>

    <v-spacer></v-spacer>
    <v-divider></v-divider>
    <v-card-actions class="px-4 pt-5 justify-space-between">
      <div
        v-if="recipe.recipe_prep_time && recipe.recipe_prep_time_text"
        class="mr-auto d-flex align-center"
      >
        <v-icon size="32" class="primary--text text--darken-1 pr-2"
          >$alarm</v-icon
        >{{ recipe.recipe_prep_time }}
        {{ recipe.recipe_prep_time_text }}
      </div>
      <div
        v-if="recipe.servings && recipe.servings_type"
        class="ml-auto d-flex align-center"
      >
        <v-icon size="32" class="primary--text text--darken-1 pr-2 "
          >$groups</v-icon
        >{{ recipe.servings }}
        {{ recipe.servings_type }}
      </div>
      <v-btn
        v-if="recipe.showButton"
        color="primary"
        block
        :to="{
          name: 'RecipeDetail',
          params: { postName: recipe.slug }
        }"
      >
        Scopri la ricetta
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  name: "RecipeCard",
  props: {
    recipe: {
      type: Object,
      required: true
    }
  }
};
</script>
