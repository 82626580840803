var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"fill-height mx-auto rounded-lg d-flex flex-column",attrs:{"elevation":"5","to":{
    name: 'RecipeDetail',
    params: { postName: _vm.recipe.slug }
  }}},[_c('v-img',{staticClass:"recipe-img",attrs:{"height":"250","src":_vm.recipe.featured_image_url,"alt":`Ricetta: ${_vm.recipe.title.rendered}`}}),_c('v-card-subtitle',{staticClass:"primary--text font-weight-bold text-body-2 pb-0"},[_vm._v(_vm._s(_vm.recipe.course))]),_c('v-card-title',{staticClass:"card-description recipe-title pt-1 pb-0 mb-2",domProps:{"innerHTML":_vm._s(_vm.recipe.title.rendered)}}),_c('v-card-text',{staticClass:"card-description-secondary"},[_vm._v(" "+_vm._s(_vm.recipe.raw_excerpt)+" ")]),_c('v-spacer'),_c('v-divider'),_c('v-card-actions',{staticClass:"px-4 pt-5 justify-space-between"},[(_vm.recipe.recipe_prep_time && _vm.recipe.recipe_prep_time_text)?_c('div',{staticClass:"mr-auto d-flex align-center"},[_c('v-icon',{staticClass:"primary--text text--darken-1 pr-2",attrs:{"size":"32"}},[_vm._v("$alarm")]),_vm._v(_vm._s(_vm.recipe.recipe_prep_time)+" "+_vm._s(_vm.recipe.recipe_prep_time_text)+" ")],1):_vm._e(),(_vm.recipe.servings && _vm.recipe.servings_type)?_c('div',{staticClass:"ml-auto d-flex align-center"},[_c('v-icon',{staticClass:"primary--text text--darken-1 pr-2",attrs:{"size":"32"}},[_vm._v("$groups")]),_vm._v(_vm._s(_vm.recipe.servings)+" "+_vm._s(_vm.recipe.servings_type)+" ")],1):_vm._e(),(_vm.recipe.showButton)?_c('v-btn',{attrs:{"color":"primary","block":"","to":{
        name: 'RecipeDetail',
        params: { postName: _vm.recipe.slug }
      }}},[_vm._v(" Scopri la ricetta ")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }