var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"fill-height mx-auto my-2 rounded-lg",attrs:{"elevation":"5"}},[_c('v-img',{attrs:{"height":"250","src":_vm.posts.featured_image_url,"alt":`Blog: ${_vm.posts.title.rendered}`}}),_c('router-link',{staticClass:"card-title",attrs:{"to":{
      name: 'ArticleList',
      query: { cat: _vm.posts.categories[0].toString() }
    }}},[_c('v-card-subtitle',{staticClass:"primary--text font-weight-bold"},[_vm._v(" "+_vm._s(_vm.posts.category_names[0])+" ")])],1),_c('v-card-title',{staticClass:"card-description pa-3 body-1 font-weight-bold d-flex align-start",style:(`height:${_vm.maxHeight}px;`),domProps:{"innerHTML":_vm._s(_vm.posts.title.rendered)}}),_c('v-divider',{staticClass:"my-3"}),_c('v-card-actions',{staticClass:"pa-4 justify-space-between",staticStyle:{"min-height":"60px"}},[_c('router-link',{staticStyle:{"color":"inherit"},attrs:{"to":{
        name: 'ArticleDetail',
        params: { postName: _vm.posts.slug }
      }}},[_c('span',{staticClass:"text-underline"},[_vm._v("Leggi l'articolo")])]),_c('ShareButtons',{attrs:{"post":_vm.posts,"direction":"top","slug":_vm.posts.slug}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }